import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export const Products = (props) => {
  return (
    <div id="products" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Products & Services</h2>
        </div>
        <div className="row">
          <div className="portfolio-items p-0">
            {props.data
              ? props.data.map((d, i) => (
                <div key={i} className="col-sm-6 col-md-4 col-lg-4" style={{ padding: "2px" }}>
                    <img src={d.img} className="img-responsive" alt={d.title} />
                    <div className="overlay">
                     <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={1}>
                        <div id={`title-${i}`} className="gradient-title">{d.title}</div>
                    </ScrollAnimation>
                    </div>
                  </div>
              ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
