import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1 intro-text">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={1}
                >
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#products"
                  className="btn btn-custom btn-lg page-scroll"
                  >
                  Learn More
                </a>
                  </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
