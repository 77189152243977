import React, { useState } from "react";

import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/captions.css";

export const Image = ({ title, largeImage, smallImage, gallery }) => {
  const [islightBoxOpen, setLightBoxOpen] = useState(false);

  return (
    <>
      <div className="portfolio-item">
        <div className="hover-bg" onClick={() => setLightBoxOpen(true)}>
          <div className="hover-text">
            <h4>{title}</h4>
          </div>
          <img src={smallImage} className="img-responsive" alt={title} />
        </div>
      </div>
      {islightBoxOpen && (
        <Lightbox
          open={largeImage}
          close={() => setLightBoxOpen(false)}
          slides={gallery}
          plugins={[Fullscreen, Counter, Captions]}
          styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
          controller={{ closeOnBackdropClick: true }}
        />
      )}
    </>
  );
};
