import React from "react";
import { VideoPlayer } from "@videojs-player/react";
import "video.js/dist/video-js.css";
import "@videojs/themes/dist/forest/index.css";

export const Collaboration = (props) => {
  return (
    <div id="collaborations" className="text-center">
      <div className="container">
        <div className="section-title collab-section">
          <h2>collaborations</h2>
          <p>Unveiling our new partnership</p>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <VideoPlayer
            className="vjs-theme-forest"
            src="https://res.cloudinary.com/ducv5uad3/video/upload/v1707586176/CollabVideo_ms90yi.mp4"
            poster="https://res.cloudinary.com/ducv5uad3/image/upload/v1707586859/alumira/poster_iydov5.png"
            controls
            loop={true}
            volume={0.6}
            fluid
            height={720}
            width={1280}
          />
        </div>
      </div>
    </div>
  );
};
