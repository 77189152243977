import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 d-flex justify-content-center">
            <ScrollAnimation
              animateIn="fadeInLeft"
              animateOnce={true}
              duration={1}
            >
              <img
                src="https://res.cloudinary.com/ducv5uad3/image/upload/v1700321183/alumira/qqv2xlm0zkq8fb6hmjxm.svg"
                className="img-responsive mx-auto"
                alt=""
              />
            </ScrollAnimation>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                duration={1}
              >
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
